import { useCopy } from '@/compositions/copy';
import { usePopup } from '@/compositions/popup';
import { objectPropertyService } from '@/modules/common/api';
import {
  genMenuItemCopy,
  genMenuItemEdit,
  genMenuItemShowHistory
} from '@/utils/list-generators';
import { formatCoordinates } from '@/utils';
import useHistoryStack, {
  componentAliases
} from '@/compositions/history-stack';
import router from '@/router';

export function useCoordinatesItemMenu() {
  const popup = usePopup();
  const { copyWithAlert } = useCopy();
  const { getNextRoute } = useHistoryStack();

  return {
    genMenu: (property, objectId) => {
      const {
        id,
        value: { lat, lon, alt }
      } = property;
      return [
        genMenuItemEdit(() => {
          popup.open({
            component: () =>
              import('@/components/popup/PopupCoordinateEdit.vue'),
            props: {
              lat,
              lon,
              alt,
              onConfirm: value =>
                objectPropertyService.update(id, {
                  value: {
                    lon: Number(value.lon),
                    lat: Number(value.lat),
                    alt: Number(value.alt)
                  }
                })
            }
          });
        }),
        genMenuItemCopy(async () =>
          copyWithAlert(formatCoordinates({ lat, lon, alt }, false))
        ),
        genMenuItemShowHistory(async () => {
          await router.push(
            getNextRoute({
              component: componentAliases.hec,
              props: {
                showHideLinesAction: true,
                showAnimateTrackAction: true,
                bench: 30,
                ids: [id],
                monitoringItem: {
                  id: objectId,
                  name: property.humanReadable,
                  type: 'geoItem'
                }
              }
            })
          );
        })
      ];
    }
  };
}
